import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  homeIcon,
  instagramIcon,
  calendarIcon,
  envelopeIcon,
  facebookIcon,
} from '../Icons';

import './nav.sass';

class Nav extends Component {
  render() {
    return (
      <nav className="menu">
        <Link className="menu-item" to="/">
          {homeIcon}
        </Link>
        <Link className="menu-item" to="/calendar">
          {calendarIcon}
        </Link>
        <a
          href="https://www.instagram.com/dannylipsitzmusic"
          className="menu-item"
          rel="noopener noreferrer"
          target="_blank"
        >
          {instagramIcon}
        </a>
        <a
          className="menu-item"
          href="https://www.facebook.com/dannylipsitzmusic"
          rel="noopener noreferrer"
          target="_blank"
        >
          {facebookIcon}
        </a>
        <Link className="menu-item" to="/contact">
          {envelopeIcon}
        </Link>
      </nav>
    );
  }
}

export default Nav;
