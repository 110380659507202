import { constants } from './mobile-actions';

const initialMobileMenu = {
  position: 'absolute',
  top: '-9999px',
  left: '-9999px',
  opacity: 0,
};

const blankUI = {
  styles: '',
  mobileMenuStyle: initialMobileMenu,
  isMenuOn: false,
  isMobile: false,
};

export default function UIReducer(state = blankUI, action) {
  switch (action.type) {
    case constants.ANIMATE_MENU:
      return Object.assign({}, state, {
        [action.key]: { ...state[action.key], ...action.payload },
      });
    case constants.HIDE_MENU:
    case constants.SHOW_MENU:
    case constants.IS_MOBILE:
    case constants.IS_NOT_MOBILE:
    case constants.IS_LANDSCAPE:
    case constants.IS_PORTRAIT:
      return Object.assign({}, state, {
        [action.key]: action.value,
      });
    default:
      return state;
  }
}
