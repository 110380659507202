import { connect } from 'react-redux';
import HeaderComponent from './HeaderComponent';
import { menuOn, menuOff } from '../Root/mobile-actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  return {
    UI: state.UI,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuOn: () => dispatch(menuOn()),
    menuOff: () => dispatch(menuOff()),
  };
};

const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
);

export default Header;
