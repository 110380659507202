import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.pageSlug = this.props.pageSlug
      ? this.props.pageSlug
      : this.props.location.pathname.slice(1);
  }

  componentDidMount() {
    if (!this.props.pages.data.find(page => page.slug === this.pageSlug)) {
      this.props.fetchPage({
        slug: this.pageSlug,
      });
    }
  }

  render() {
    const found = this.props.pages.data.find(
      page => page.slug === this.pageSlug
    );

    return (
      <section className="contact" onSubmit={this.props.onSubmit}>
        {found ? <ReactMarkdown source={found.raw.markdown} /> : null}
      </section>
    );
  }
}

export default Contact;
