import React from 'react';

const PressKit = () => {
  window.location =
    'https://s3.amazonaws.com/api.dannylipsitz.com/Press+Kit+Brass+Tacks+RnR.zip';

  return (
    <section>
      <p>Thank you for downloading the presskit!</p>
    </section>
  );
};

export default PressKit;
