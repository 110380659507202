import { connect } from 'react-redux';

import { fetchCalendar } from './calendar-actions';
import { fetchVenues } from './venue-actions';
import { fetchBands } from './band-actions';

import CalendarComponent from './CalendarComponent';

import './calendar.sass';

const mapStateToProps = state => {
  return {
    UI: state.UI,
    calendar: state.calendar,
    bands: state.bands,
    venues: state.venues,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCalendar: (query = {}) => dispatch(fetchCalendar(query)),
    fetchVenues: (query = {}) => dispatch(fetchVenues(query)),
    fetchBands: (query = {}) => dispatch(fetchBands(query)),
  };
};

const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent);

export default Calendar;
