import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { debounce } from 'smalldash';

import PageLayout from '../PageLayout';

import Routes from '../Routes';
import Header from '../Header';
import Quotes from '../Quotes';

import './style.sass';

class Root extends Component {
  constructor(props) {
    super(props);

    this.GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    ReactGA.initialize(this.GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.handleWindowSizeChange = debounce(
      this.handleWindowSizeChange.bind(this),
      300
    );
  }

  componentDidMount() {
    this.props.windowDetector(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.props.windowDetector(window.innerWidth, window.innerHeight);
  }

  render() {
    return (
      <PageLayout>
        <div className="empty" />
        <Header />
        <Quotes />
        <Routes />
      </PageLayout>
    );
  }
}

export default Root;
