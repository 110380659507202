import React, { Component } from 'react';

import './video.sass';

class Video extends Component {
  constructor(props) {
    super(props);
    this.classNames = this.concatenateClassNames();
    this.title = this.props.title || 'video title';
    this.src = this.props.src || undefined;
  }

  concatenateClassNames() {
    let inherited = this.props.className || '';
    if (inherited) return 'video ' + inherited;
    else return 'video';
  }

  render() {
    return (
      <div className={this.classNames}>
        <div className="h_iframe">
          <iframe
            title={this.title}
            src={this.src}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export default Video;
