import React, { Component } from 'react';
import { TIMESTAMP, REFRESH } from '../utils/dates';
import CalendarDisplay from './CalendarDisplay';

class Calendar extends Component {
  componentDidMount() {
    const query = { per_page: 100 };
    if (TIMESTAMP - this.props.calendar.loadedAt > REFRESH)
      this.props.fetchCalendar(query);
    if (TIMESTAMP - this.props.bands.loadedAt > REFRESH)
      this.props.fetchBands(query);
    if (TIMESTAMP - this.props.venues.loadedAt > REFRESH)
      this.props.fetchVenues(query);
  }

  render() {
    return (
      <section className="calendar">
        <CalendarDisplay
          bands={this.props.bands}
          venues={this.props.venues}
          calendar={this.props.calendar}
          orientation={this.props.UI.orientation}
          isMobile={this.props.UI.isMobile}
        />
      </section>
    );
  }
}

export default Calendar;
