import React from 'react';
import { TIMESTAMP, ensureDateObject } from '../utils/dates';
import CalendarMarkup from './CalendarMarkupComponent';

const Fragment = React.Fragment;

function CalendarRange(props) {
  const currentGigs = props.calendar
    .filter(gig => Boolean(gig.acf.date))
    .filter(gig => dateOnly(TIMESTAMP) <= dateOnly(gig.acf.date))
    .sort(
      (a, b) => ensureDateObject(a.acf.date) - ensureDateObject(b.acf.date)
    );

  const pastGigs = props.calendar
    .filter(gig => Boolean(gig.acf.date))
    .filter(gig => dateOnly(TIMESTAMP) > dateOnly(gig.acf.date))
    .sort(
      (a, b) => ensureDateObject(b.acf.date) - ensureDateObject(a.acf.date)
    );

  return (
    <Fragment>
      <CalendarMarkup
        bands={props.bands}
        venues={props.venues}
        calendar={currentGigs}
      />
      <h2 className="calendar-label">Past Performances</h2>
      <CalendarMarkup
        bands={props.bands}
        venues={props.venues}
        calendar={pastGigs}
      />
    </Fragment>
  );
}

export function dateOnly(date) {
  const dateObj = ensureDateObject(date);
  dateObj.setHours(0);
  dateObj.setMinutes(0);
  dateObj.setSeconds(0);
  dateObj.setMilliseconds(0);
  return dateObj;
}

export default CalendarRange;
