const instagramPhoto = {
  height: '',
  width: '',
};

// const blankStyles = {
// 	instagramPhoto,
// };

const blankInsta = {
  status: 'blank',
  loadedAt: '',
  data: [],
  pagination: {},
  meta: {},
  styles: instagramPhoto,
};

// function styleReducer(state = {}, action) {
// 	switch (action.type) {
// 		case UPDATE_INSTAGRAM_STYLES:
// 			return Object.assign({}, state, {
// 				instagramPhoto: action.payload,
// 			});
// 		default:
// 			return state;
// 	}
// }

export function instagramReducer(state = blankInsta, action) {
  switch (action.type) {
    case 'INSTAGRAM_POSTS_RESOLVE':
      return Object.assign({}, state, {
        pagination: action.payload.pagination,
        meta: action.payload.meta,
        data: [...state.data, ...action.payload.data],
      });
    case 'INSTAGRAM_POSTS_ERROR':
    case 'INSTAGRAM_POSTS_REQUEST':
    case 'INSTAGRAM_POSTS_SUCCESS':
      return Object.assign({}, state, {
        [action.key]: action.value,
      });
    case 'UPDATE_INSTAGRAM_STYLES':
      return Object.assign({}, state, {
        styles: action.payload,
      });
    default:
      return state;
  }
}
