import React, { Component } from 'react';
import SiteMap from './SiteMapContainer';
import MailForm from '../MailForm';

import './footer.sass';

const thisYear = new Date().getFullYear();

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <MailForm />
        <SiteMap />
        <p className="footer-text">
          <i className="fa fa-copyright" /> {thisYear} Danny Lipsitz
        </p>
        <p className="footer-text">
          <i className="fa fa-code" /> Web Design by{' '}
          <a
            href="https://www.linkedin.com/in/nickmyersnyc/"
            rel="noopener noreferrer"
          >
            Nick Myers
          </a>
        </p>
      </footer>
    );
  }
}

export default Footer;
