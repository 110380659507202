import React, { Component } from 'react';
import Title from '../Title';
import MobileNav from '../MobileNav';
import Nav from '../Nav';

import './header.sass';

class Header extends Component {
  render() {
    if (this.props.UI.isMobile) {
      return (
        <header>
          <div className="mobile-header">
            <i
              className="fa fa-bars fa-large hamburger"
              onClick={
                this.props.UI.menuOn ? this.props.menuOff : this.props.menuOn
              }
            />
            <div className="title">
              <Title text="Danny Lipsitz" />
            </div>
          </div>
          <MobileNav />
        </header>
      );
    } else {
      return (
        <header className="header">
          <div className="title">
            <Title text="Danny Lipsitz" />
          </div>
          <Nav />
        </header>
      );
    }
  }
}

export default Header;
