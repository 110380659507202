import React from 'react';

import './icons.sass';

function iconStacker(icon) {
  const stacked = `fa fa-${icon} fa-stack-1x`;

  return (
    <span className="fa-stack fa-lg">
      <i className="fa fa-square-o fa-stack-2x" />
      <i style={{ marginTop: 2 }} className={stacked} />
    </span>
  );
}

export const homeIcon = iconStacker('home');
export const bandcampIcon = iconStacker('bandcamp');
export const envelopeIcon = iconStacker('envelope');
export const facebookIcon = iconStacker('facebook');
export const calendarIcon = iconStacker('calendar');
export const instagramIcon = iconStacker('instagram');
