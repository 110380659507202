import { fetchACF } from '../utils/request';
import { TIMESTAMP } from '../utils/dates';
import WPActions from '../utils/wp-actions';
import generateActions from '../utils/action-creator';

const { constants, actions } = generateActions(WPActions, 'PAGE');

function pagePostsHook() {
  return (dispatch, getState) => {
    const { totalPages, currentPage } = getState().pages;

    // check if done requesting
    if (currentPage > totalPages) {
      dispatch(actions.pagePostsSuccess());
      dispatch(actions.pagePostsTimestamp(TIMESTAMP));
    }
  };
}

function pagePostsHeader(Header) {
  return (dispatch, getState) => {
    const { currentPage, totalPages } = getState().pages;

    const x_wp_totalpages = Number(Header.get('x-wp-totalpages'));

    if (x_wp_totalpages !== totalPages)
      dispatch(actions.pagePostsTotalPages(x_wp_totalpages));

    // update current page before dispatching async action
    dispatch(actions.pagePostsCurrentPage(currentPage + 1));

    // check to see if we need to paginate
    if (currentPage < x_wp_totalpages) {
      // dispatch next async action
      dispatch(
        fetchPages({
          per_page: 10,
          page: currentPage + 1,
        })
      );
    }
  };
}

const fetchPages = fetchACF({
  type: 'pages',
  header: pagePostsHeader,
  pre: actions.pagePostsRequest,
  success: actions.pagePostsResolve,
  error: actions.pagePostsError,
  post: pagePostsHook,
});

const fetchPage = fetchACF({
  type: 'pages',
  pre: actions.pagePostsRequest,
  success: actions.pagePostsResolve,
  error: actions.pagePostsError,
});

export { constants, fetchPages, fetchPage };
