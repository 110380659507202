import { connect } from 'react-redux';
import MobileNavComponent from './MobileNavComponent';
import { menuOff } from '../Root/mobile-actions';

const mapStateToProps = state => {
  return {
    UI: state.UI,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuOff: () => dispatch(menuOff()),
  };
};

const MobileNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavComponent);

export default MobileNavContainer;
