import { combineReducers } from 'redux';

import {
  venueReducer,
  calendarReducer,
  bandReducer,
  pageReducer,
  quoteReducer,
} from './post-reducer';
import UIReducer from './components/Root/UI';
import contactReducer from './components/Contact/contact-reducer';
import { instagramReducer } from './components/Instagram/instagram-reducer';

const rootReducer = combineReducers({
  UI: UIReducer,
  instagram: instagramReducer,
  calendar: calendarReducer,
  venues: venueReducer,
  bands: bandReducer,
  contact: contactReducer,
  pages: pageReducer,
  quotes: quoteReducer,
});

export default rootReducer;
