import { connect } from 'react-redux';
import PageComponent from './PageComponent';
import { fetchPage, fetchPages } from './page-actions';

const mapStateToProps = state => {
  return {
    pages: state.pages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPage: (query = {}) => dispatch(fetchPage(query)),
    fetchPages: (query = {}) => dispatch(fetchPages(query)),
  };
};

const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);

export default PageContainer;
