/** @flow */
import * as React from 'react';
// types
import type { Props } from './types';
// styles
import './page-layout.sass';

class PageLayout extends React.Component<Props> {
  render() {
    return <section className="pageLayout">{this.props.children}</section>;
  }
}

export default PageLayout;
