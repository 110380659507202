import { fetchACF } from '../utils/request';
import { TIMESTAMP } from '../utils/dates';
import WPActions from '../utils/wp-actions';
import generateActions from '../utils/action-creator';

const { constants, actions } = generateActions(WPActions, 'CALENDAR');

function calendarPostsHook() {
  return (dispatch, getState) => {
    const { totalPages, currentPage } = getState().calendar;

    // check if done requesting
    if (currentPage > totalPages) {
      dispatch(actions.calendarPostsSuccess());
      dispatch(actions.calendarPostsTimestamp(TIMESTAMP));
    }
  };
}

function calendarPostsHeader(Header) {
  return (dispatch, getState) => {
    const { currentPage, totalPages } = getState().calendar;

    const x_wp_totalpages = Number(Header.get('x-wp-totalpages'));

    if (x_wp_totalpages !== totalPages)
      dispatch(actions.calendarPostsTotalPages(x_wp_totalpages));

    // update current page before dispatching async action
    dispatch(actions.calendarPostsCurrentPage(currentPage + 1));

    // check to see if we need to paginate
    if (currentPage < x_wp_totalpages) {
      // dispatch next async action
      dispatch(
        fetchCalendar({
          per_page: 100,
          page: currentPage + 1,
        })
      );
    }
  };
}

const fetchCalendar = fetchACF({
  type: 'shows',
  pre: actions.calendarPostsRequest,
  header: calendarPostsHeader,
  success: actions.calendarPostsResolve,
  error: actions.calendarPostsError,
  post: calendarPostsHook,
});

export { constants, fetchCalendar };
