import * as React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { FormItem, FormSubmit } from '../Contact/ContactComponent';

import './mail-form.sass';

const url = process.env.REACT_APP_MAIL_CHIMP_LIST_URL;

export function MailForm() {
  const [email, setEmail] = React.useState('');

  return (
    <div className="mail-form">
      <h4 className="mail-form-header">Join the mailing list!</h4>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
          const handleSubmit = e => {
            e.preventDefault();
            subscribe({ EMAIL: email });
          };

          return (
            <form onSubmit={handleSubmit}>
              {status !== 'error' && message && (
                <p className="message">{message}</p>
              )}
              {status === 'error' && message && (
                <p className="error">{message}</p>
              )}
              <FormItem
                name="email"
                label="Email"
                type="email"
                className="form-email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required="true"
              />
              <FormSubmit className="form-item-buttons" value="Signup" />
            </form>
          );
        }}
      />
    </div>
  );
}
