import { fetchACF } from '../utils/request';
import { TIMESTAMP } from '../utils/dates';
import WPActions from '../utils/wp-actions';
import generateActions from '../utils/action-creator';

const { constants, actions } = generateActions(WPActions, 'BAND');

function bandPostsHook() {
  return (dispatch, getState) => {
    const { totalPages, currentPage } = getState().bands;

    // check if done requesting
    if (currentPage > totalPages) {
      dispatch(actions.bandPostsSuccess());
      dispatch(actions.bandPostsTimestamp(TIMESTAMP));
    }
  };
}

function bandPostsHeader(Header) {
  return (dispatch, getState) => {
    const { currentPage, totalPages } = getState().bands;

    const x_wp_totalpages = Number(Header.get('x-wp-totalpages'));

    if (x_wp_totalpages !== totalPages)
      dispatch(actions.bandPostsTotalPages(x_wp_totalpages));

    // update current page before dispatching async action
    dispatch(actions.bandPostsCurrentPage(currentPage + 1));

    // check to see if we need to paginate
    if (currentPage < x_wp_totalpages) {
      // dispatch next async action
      dispatch(
        fetchBands({
          per_page: 100,
          page: currentPage + 1,
        })
      );
    }
  };
}

const fetchBands = fetchACF({
  type: 'bands',
  header: bandPostsHeader,
  pre: actions.bandPostsRequest,
  success: actions.bandPostsResolve,
  error: actions.bandPostsError,
  post: bandPostsHook,
});

export { fetchBands, constants };
