import { fetchACF } from '../utils/request';
import { TIMESTAMP } from '../utils/dates';
import WPActions from '../utils/wp-actions';
import generateActions from '../utils/action-creator';

const { constants, actions } = generateActions(WPActions, 'VENUE');

function venuePostsHook() {
  return (dispatch, getState) => {
    const { totalPages, currentPage } = getState().venues;

    // check if done requesting
    if (currentPage > totalPages) {
      dispatch(actions.venuePostsSuccess());
      dispatch(actions.venuePostsTimestamp(TIMESTAMP));
    }
  };
}

function venuePostsHeader(Header) {
  return (dispatch, getState) => {
    const { currentPage, totalPages } = getState().venues;

    const x_wp_totalpages = Number(Header.get('x-wp-totalpages'));

    if (x_wp_totalpages !== totalPages)
      dispatch(actions.venuePostsTotalPages(x_wp_totalpages));

    // update current page before dispatching async action
    dispatch(actions.venuePostsCurrentPage(currentPage + 1));

    // check to see if we need to paginate
    if (currentPage < x_wp_totalpages) {
      // dispatch next async action
      dispatch(
        fetchVenues({
          per_page: 100,
          page: currentPage + 1,
        })
      );
    }
  };
}

const fetchVenues = fetchACF({
  type: 'venues',
  pre: actions.venuePostsRequest,
  header: venuePostsHeader,
  success: actions.venuePostsResolve,
  error: actions.venuePostsError,
  format: formatVenuePosts,
  post: venuePostsHook,
});

// statuses => blank, loading, filtered, resolved, error

function formatVenuePosts(res) {
  const formatted = res.map(each => {
    const { name, address, website } = each.acf;
    const mapURL = googleMapLinkGenerator(name, address);
    return Object.assign({}, each, {
      acf: {
        name,
        address,
        website,
        mapURL,
      },
    });
  });

  return formatted;
}

function googleMapLinkGenerator(name, address) {
  let baseURL = 'https://www.google.com/maps/search/?api=1&query=';
  let regex = /\W/gi;
  let arr = [name, address];
  arr.forEach(each => {
    baseURL += each.toString().replace(regex, '+').toLowerCase() + '+';
  });
  return baseURL.replace(/\+{2,}/gi, '+');
}

export { fetchVenues, constants };
