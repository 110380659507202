import WebFont from 'webfontloader';

const fonts = {
  google: {
    families: [
      'Roboto:400,100,100italic,400italic,300italic,300,500,500italic,700,700italic,900,900italic',
    ],
  },
  custom: {
    families: ['FontAwesome'],
    urls: [
      'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
    ],
  },
};

WebFont.load(fonts);

export default WebFont;
