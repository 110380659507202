import React from 'react';
import { dateOnly } from './CalendarRange';

function today() {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

const className = gig => {
  return gig.acf.featured && dateOnly(gig.acf.date) >= today()
    ? 'calendar-entry calendar-entry-featured'
    : 'calendar-entry';
};

function CalendarMarkup(props) {
  const markup = props.calendar.map(gig => {
    const band = props.bands.find(band => band.id === gig.acf.band);
    const venue = props.venues.find(venue => venue.id === gig.acf.venue);

    const artistName = band.acf.name || null;
    const artistWebsite = band.acf.website || null;
    const venueName = venue.acf.name || null;
    const venueAddress = venue.acf.address || null;
    const venueAddressWebsite = venue.acf.mapURL || null;
    const venueWebsite = venue.acf.website || null;
    const time = gig.acf.end_time
      ? `${gig.acf.start_time} - ${gig.acf.end_time}`
      : gig.acf.start_time;

    return (
      <tr className={className(gig)} key={gig.id}>
        <td className="entry-date">{gig.acf.date}</td>
        <td className="entry-time">{time}</td>
        <td className="artist-name">
          <a href={artistWebsite} target="_blank" rel="noopener noreferrer">
            {artistName}
          </a>
        </td>
        <td className="venue-name">
          <a href={venueWebsite} target="_blank" rel="noopener noreferrer">
            {venueName}
          </a>
        </td>
        <td className="venue-address">
          <a
            href={venueAddressWebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            {venueAddress}
          </a>
        </td>
      </tr>
    );
  });

  if (markup.length > 0) {
    return (
      <table className="calendar">
        <tbody>
          <Headings />
          {markup}
        </tbody>
      </table>
    );
  } else {
    return <p>No gigs booked during this time period</p>;
  }
}

function Headings() {
  return (
    <tr className="calendar-entry">
      <th className="entry-date">Date</th>
      <th className="entry-date">Time</th>
      <th className="artist-name">Artist Name</th>
      <th className="venue-name">Venue</th>
      <th className="venue-address">Address</th>
    </tr>
  );
}

export default CalendarMarkup;
