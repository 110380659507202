/** @flow */
import * as React from 'react';
import { Route } from 'react-router-dom';
// routes
import Home from '../Home';
import Contact from '../Contact';
// import Instagram from '../Instagram';
import Calendar from '../Calendar';
import PressKit from '../PressKit';
import Footer from '../Footer';
// types
import type { Props } from './types';
// styles
import './routes.sass';

class Routes extends React.Component<Props> {
  render() {
    return (
      <section className="content">
        <main className="main">
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
          {/* <Route path="/instagram" component={Instagram} /> */}
          <Route path="/calendar" component={Calendar} />
          <Route path="/presskit" component={PressKit} />
        </main>
        <Footer />
      </section>
    );
  }
}

export default Routes;
