import React from 'react';
import Spinner from '../Spinner';
import CalendarRange from './CalendarRange';

function CalendarDisplay(props) {
  const status = cascadingStatus([
    props.calendar.staus,
    props.venues.status,
    props.bands.status,
  ]);

  switch (status) {
    default:
    case 'blank':
    case 'loading':
      return <Spinner />;
    case 'error':
      return (
        <p className="error">
          There was a problem loading calendar. Please try again.
        </p>
      );
    case 'resolved':
    case 'filtered':
      return (
        <CalendarRange
          bands={props.bands.data}
          venues={props.venues.data}
          calendar={props.calendar.data}
          months={props.months}
        />
      );
  }
}

function cascadingStatus(statuses) {
  if (statuses.includes('error')) return 'error';
  else if (statuses.includes('loading')) return 'loading';
  else if (statuses.includes('resolved')) return 'resolved';
}

export default CalendarDisplay;
