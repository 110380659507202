import { MAIL_API_KEY, MAIL_URL } from './constants';

export default function fetchPost({
  method = 'POST',
  endpoint = 'v2/send',
  pre,
  header,
  success,
  error,
  post,
} = {}) {
  return (recipient, body) => {
    return dispatch => {
      if (pre) dispatch(pre());

      let url = `${MAIL_URL}/${endpoint}/${recipient}`;

      return fetch(url, {
        method,
        headers: {
          Authorization: MAIL_API_KEY,
          'Content-Type': 'application/json',
        },
        body,
      })
        .then(res => {
          if (header) dispatch(header(res.headers));
          return res.json();
        })
        .then(data => dispatch(success(data)))
        .then(() => {
          if (post) return dispatch(post());
        })
        .catch(err => {
          dispatch(error(err));
        });
    };
  };
}
