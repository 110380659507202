import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router';
import api from '../api';
// styles
import './quotes.sass';

class Quotes extends React.Component {
  state = {
    status: 'loading',
    data: [],
  };

  getQuotes = () =>
    new Promise((res, rej) => {
      api
        .get('/quotes')
        .then(response => res(response))
        .catch(error => rej(error));
    });

  componentDidMount() {
    this.getQuotes()
      .then(response => {
        this.setState(state => ({
          status: 'resolved',
          data: [...state.data, ...response.data],
        }));
      })
      .catch(() => {
        this.setState({ status: 'error' });
      });
  }

  render() {
    return this.state.status === 'resolved' && this.props.match.isExact ? (
      <section className="quotes">
        {this.state.data.map(
          quote =>
            quote.acf.state && (
              <article key={quote.id} className="quote">
                <section className="quote_left">
                  <q className="quote_q">
                    <ReactMarkdown
                      className="quote_markdown"
                      source={`${quote.acf.quote}`}
                    />
                  </q>
                  <cite className="quote_cite">- {quote.acf.author}</cite>
                </section>
                <figure className="quote_figure">
                  <img
                    src={quote.acf.photo.url}
                    className="quote_img"
                    alt={`${quote.acf.author}-quote-danny-lipsitz`}
                  />
                </figure>
              </article>
            )
        )}
      </section>
    ) : (
      <section className="empty" />
    );
  }
}

export default withRouter(Quotes);
