import { connect } from 'react-redux';
import HomeComponent from './HomeComponent';
import { menuOff } from '../Root/mobile-actions';

const mapStateToProps = state => {
  return {
    UI: state.UI,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    menuOff: () => dispatch(menuOff()),
  };
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;
