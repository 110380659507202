import { connect } from 'react-redux';
import RootComponent from './RootComponent';
import { windowDetector } from './mobile-actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  return {
    UI: state.UI,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    windowDetector: (width, height) => dispatch(windowDetector(width, height)),
  };
};

const Root = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootComponent)
);

export default Root;
